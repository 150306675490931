import React from "react"
import {graphql, Link} from 'gatsby'
import Img from "gatsby-image";
import Layout from "../components/layout"
import SEO from "../components/seo";

export default function Category({ data }) {
    const category = data.wagtail.category;

    const getRibbon = (piece) => {
        if(piece.commission) {
            return (<span className="ribbon ribbon--red">Commission</span>);
        } if(piece.sold) {
            return (<span className="ribbon ribbon--red">Sold</span>);
        }
    }

    return (
        <Layout>
            <SEO title={category.name}
                 image={category?.image?.thumbnail?.localFile?.childImageSharp?.fluid.src} />
            <div className="h1">
                <h1>{category.name}</h1>
            </div>
            <div className="piece-list row">
                {category.pieces.map((piece) => {
                    return <div className="col col-xl-3 col-md-6 col-12" key={piece.slug}>
                        <div className="category">
                            {getRibbon(piece)}
                            <Link to={`/piece/${piece.slug}`}>
                                <Img fluid={piece?.image?.thumbnail?.localFile?.childImageSharp?.fluid} />
                            </Link>
                        </div>
                    </div>
                })}
            </div>
        </Layout>
    )
}

export const query = graphql`
    query($slug: String!) {
        wagtail {
            category(slug: $slug) {
                name
                slug
                image {
                    url
                    height
                    width
                    ...thumbnail
                }
                pieces {
                    slug
                    title
                    commission
                    sold
                    image {
                        url
                        height
                        width
                        ...thumbnail
                    }
                }
            }
        }
    }
`;
